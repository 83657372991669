const truncateSnippet = (str: string, maxLength: number) => {
  let trimmedString = str

  if (str.length > maxLength) {
    trimmedString = str.substring(0, maxLength - 1)
    trimmedString =
      trimmedString.substring(
        0,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
      ) + "..."
  }
  return trimmedString
}

export default truncateSnippet
