import {
  IconKey,
  IconWifi,
  IconParkingManagement,
  IconMobilePhone,
  IconDoorOpen,
  IconSmartHome,
  IconSmartPackageRoom,
  IconClipboardTask,
  IconPhone,
  IconSearch,
  IconCheck,
  IconSmrtOperations,
  IconSmrtInspection,
  IconSmrtCommunities,
} from "@sr/gatsby-theme-ui"
import React, { ReactNode } from "react"

export interface NavigationOtherNavInfo {
  demo: {
    nav: string
    link: string
    internal: boolean
  }
  navInfo: {
    nav: string
    link: string
    internal: boolean
    subNavItems?: NavigationSubSectionContentNavigationItems[]
  }[]
}

export interface NavigationSubSectionContentNavigationItems {
  nav: string
  link: string
  internal?: boolean
  icon?: () => ReactNode
}

export interface NavigationSubSectionContent {
  header?: string
  simple?: boolean
  nav?: string
  link?: string
  internal?: boolean
  navSecondary?: string
  linkSecondary?: string
  secondaryInternal?: boolean
  iconAbove?: boolean
  navigationItems: NavigationSubSectionContentNavigationItems[]
}
export interface NavigationSubSection {
  nav: string
  navLink?: string
  content: NavigationSubSectionContent[]
}

const Solutions: NavigationSubSection = {
  nav: "Solutions",
  content: [
    {
      header: "Smart Communities",
      nav: "View All Solutions",
      link: "/products/",
      internal: true,
      iconAbove: true,
      navigationItems: [
        {
          nav: "Access Control",
          link: "/products/access-control/",
          internal: true,
          icon: () => <IconKey color={"var(--color-primary)"} />,
        },
        {
          nav: "Community Wifi",
          link: "/products/community-wifi",
          internal: true,
          icon: () => <IconWifi color={"var(--color-primary)"} />,
        },
        {
          nav: "Parking Management",
          link: "/products/smart-parking-management/",
          internal: true,
          icon: () => <IconParkingManagement color={"var(--color-primary)"} />,
        },
        {
          nav: "Resident App and Experience",
          link: "/products/resident-app-and-experience/",
          internal: true,
          icon: () => <IconMobilePhone color={"var(--color-primary)"} />,
        },
        {
          nav: "Self-Guided Tours",
          link: "/products/self-guided-tours/",
          internal: true,
          icon: () => <IconDoorOpen color={"var(--color-primary)"} />,
        },
        {
          nav: "Smart Apartments",
          link: "/products/smart-apartments-homes/",
          internal: true,
          icon: () => <IconSmartHome color={"var(--color-primary)"} />,
        },
        {
          nav: "Package Management",
          link: "/products/smart-package-room/",
          internal: true,
          icon: () => <IconSmartPackageRoom color={"var(--color-primary)"} />,
        },
      ],
    },
    {
      header: "Smart Operations",
      nav: "View All Integrations",
      link: "/integrations-partnerships/",
      internal: true,
      iconAbove: true,
      navigationItems: [
        {
          nav: "Audit Management",
          link: "/products/audit-management/",
          internal: true,
          icon: () => <IconClipboardTask color={"var(--color-primary)"} />,
        },
        {
          nav: "Answer Automation",
          link: "/products/answer-automation",
          internal: true,
          icon: () => <IconPhone color={"var(--color-primary)"} />,
        },
        {
          nav: "Inspection Management",
          link: "/products/inspection-management/",
          internal: true,
          icon: () => <IconSearch color={"var(--color-primary)"} />,
        },
        {
          nav: "Work Management",
          link: "/products/work-management/",
          internal: true,
          icon: () => <IconCheck color={"var(--color-primary)"} />,
        },
      ],
    },
  ],
}

const Hardware: NavigationSubSection = {
  nav: "Hardware",
  content: [
    {
      header: "Alloy Hardware",
      nav: "All Alloy Hardware",
      link: "/hardware/alloy/",
      internal: true,
      iconAbove: false,
      navigationItems: [
        {
          nav: "Hubs",
          link: "/hardware/hubs/",
          internal: true,
        },

        {
          nav: "Sensors",
          link: "/hardware/sensors/",
          internal: true,
        },
        {
          nav: "Readers",
          link: "/hardware/readers-panels-boards/",
          internal: true,
        },
        {
          nav: "Intercoms",
          link: "/hardware/intercoms/",
          internal: true,
        },
      ],
    },
    {
      header: "Additional Devices",
      nav: "All Hardware",
      link: "/hardware/",
      internal: true,
      navSecondary: "All Integrations",
      linkSecondary: "/integrations-partnerships/",
      secondaryInternal: true,
      navigationItems: [
        {
          nav: "Locks & Lockboxes",
          link: "/hardware/locks-lock-boxes/",
          internal: true,
        },
        {
          nav: "Thermostats",
          link: "/hardware/thermostats/",
          internal: true,
        },
        {
          nav: "Lighting",
          link: "/hardware/smart-lighting/",
          internal: true,
        },
      ],
    },
  ],
}

const WhySmartRent: NavigationSubSection = {
  nav: "Why SmartRent",
  content: [
    {
      header: "By Team",
      navigationItems: [
        {
          nav: "Leasing",
          link: "/products/leasing/",
          internal: true,
        },
        {
          nav: "Maintenance",
          link: "/products/maintenance/",
          internal: true,
        },
        {
          nav: "Management",
          link: "/products/management/",
          internal: true,
        },
        {
          nav: "Operations",
          link: "/products/operations/",
          internal: true,
        },
      ],
    },
    {
      header: "By Need",
      navigationItems: [
        {
          nav: "Asset Protection",
          link: "/products/asset-protection/",
          internal: true,
        },
        {
          nav: "Centralization",
          link: "/products/centralization",
          internal: true,
        },
        {
          nav: "Connected Communities",
          link: "/products/connected-communities",
          internal: true,
        },
        {
          nav: "Resident Satisfaction",
          link: "/products/resident-satisfaction/",
          internal: true,
        },
      ],
    },
    {
      header: "By Industry",
      navigationItems: [
        {
          nav: "Partner Program",
          link: "/customers/channel-partners-installers/",
          internal: true,
        },
        {
          nav: "Homebuilders",
          link: "/customers/homebuilders/",
          internal: true,
        },
        {
          nav: "Multifamily",
          link: "/customers/multifamily/",
          internal: true,
        },
        {
          nav: "Single Family Rentals",
          link: "/customers/single-family-rentals/",
          internal: true,
        },
        {
          nav: "Student Housing",
          link: "/customers/student-housing/",
          internal: true,
        },
      ],
    },
  ],
}

const Resources: NavigationSubSection = {
  nav: "Resources",
  content: [
    {
      simple: true,
      navigationItems: [
        {
          nav: "About Us",
          link: "/about-us/",
          internal: true,
        },
        {
          nav: "Leadership",
          link: "/about-us#leadership",
          internal: true,
        },
        {
          nav: "Blog",
          link: "/news/",
          internal: true,
        },
        {
          nav: "Case Studies",
          link: "/case-studies/",
          internal: true,
        },
        {
          nav: "White Papers",
          link: "/whitepapers/",
          internal: true,
        },
        {
          nav: "In The Press",
          link: "/news/press/",
          internal: true,
        },
        {
          nav: "Resident Data & Security",
          link: "/data-security-privacy/",
          internal: true,
        },
      ],
    },
  ],
}

const Contact: NavigationSubSection = {
  nav: "Contact",
  navLink: "/contact-smartrent/",
  content: [
    {
      simple: true,
      navigationItems: [
        {
          nav: "Support",
          link: "/support/",
          internal: true,
        },
        {
          nav: "Talk to Sales",
          link: "/contact-smartrent/demo/",
          internal: true,
        },
        {
          nav: "Integrate with us",
          link: "/contact-smartrent/integration-request/",
          internal: true,
        },
        {
          nav: "Be a Partner",
          link: "/customers/channel-partners-installers#channel-partners-form",
          internal: true,
        },
      ],
    },
  ],
}

const OtherNavInfo: NavigationOtherNavInfo = {
  demo: {
    nav: "Let's Talk",
    link: "/contact-smartrent/",
    internal: true,
  },
  navInfo: [
    {
      nav: "Support",
      link: "/support/",
      internal: true,
    },
    {
      nav: "Login",
      link: "https://control.smartrent.com/login",
      internal: false,
      subNavItems: [
        {
          nav: "SMRT Operations",
          link: "https://app.sightplan.com/",
          internal: false,
          icon: () => <IconSmrtOperations color={"var(--color-primary)"} />,
        },
        {
          nav: "SMRT Inspection",
          link: "https://inspect.sightplan.com/",
          internal: false,
          icon: () => <IconSmrtInspection color={"var(--color-primary)"} />,
        },
        {
          nav: "SMRT Communities",
          link: "https://control.smartrent.com/",
          internal: false,
          icon: () => <IconSmrtCommunities color={"var(--color-primary)"} />,
        },
      ],
    },
    {
      nav: "Investors",
      link: "https://investors.smartrent.com/overview/default.aspx",
      internal: false,
    },
  ],
}

export { Solutions, Hardware, WhySmartRent, Resources, Contact, OtherNavInfo }

//take each navigationItems array from each main nav item object
//create an array for the main nav item and add each navigationItems item to it
// const contact = ["/contact-smartrent/demo", "/contact-smartrent/quote"]
