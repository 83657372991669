import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import brandImage from "../../../images/brand/brandmark-primary-footer.svg"

export interface AppFooterBrandProps {
  className?: string
}

const AppFooterBrand = ({ className }: AppFooterBrandProps) => {
  return (
    <AppFooterBrandStyled className={`${className} footer-brand`}>
      <h2>
        <Link to="/">
          <img
            className="footer-brand__img"
            src={brandImage}
            alt="SmartRent.com logo"
          />
        </Link>
      </h2>
      <p className="footer-brand__text">
        SmartRent is an enterprise smart home automation company developing
        software and hardware that empowers property owners, managers and
        homebuilders to effectively manage, protect and automate daily
        operational processes utilizing our proprietary Alloy Automation.
      </p>
      <p className="footer-brand__contact">
        <span className="footer-brand__contact__title">Corporate</span>
        <br />
        <br />
        6811 E. Mayo Blvd
        <br />
        Fourth Floor
        <br />
        Phoenix, AZ 85054
        <br />
        <br />
      </p>
    </AppFooterBrandStyled>
  )
}

export default AppFooterBrand

const AppFooterBrandStyled = styled.div`
  padding: ${rem(16)} 0 0;
  display: flex;
  flex-direction: column;

  @media ${media.stUp} {
    align-items: left;
    justify-content: left;
    width: var(--st-column-default);
  }

  .footer-brand__img {
    width: 10%;
    display: block;
  }

  .footer-brand__text {
    padding: ${rem(24)} ${rem(32)} ${rem(24)} 0;
    color: var(--color-gray200);
    text-align: left;
  }
  .footer-brand__contact {
    padding: ${rem(24)} ${rem(32)} ${rem(24)} 0;
    color: var(--color-gray200);
  }
  .footer-brand__contact__title {
    font-weight: bold;
  }
`
