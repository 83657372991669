import React, { ReactNode } from "react"
import styled from "styled-components"

import { media } from "@sr/common"
import { rem } from "polished"

import { SanityImg } from "../../../types/index"
import {
  BdTitle2,
  RegBody,
  SemiBdTitle4,
  SemiBdTitle5,
} from "../../Typography/index"
import AppLayoutWidth from "../../App/Layout/Width"
import SanityImage from "gatsby-plugin-sanity-image"
import { Link } from "gatsby"

export interface SectionHeroProps {
  intro?: string | ReactNode
  introIcon?:
    | {
        _type: "sanityImg"
        data: SanityImg
      }
    | {
        _type: "reactNode"
        data: ReactNode
      }
    | {
        _type: "cloudinaryImg"
        data: string
      }
  introColor?: "onDark" | "onLight" | "primary"
  header?: string | ReactNode
  headerColor?: "onDark" | "onLight" | "primary"
  subheader?: string | ReactNode
  subheaderColor?: "onDark" | "onLight" | "primary"
  body: string | ReactNode
  bodyColor?: "onDark" | "onLight" | "primary"
  bodyPadding?: boolean
  /**
   * center align all text in hero
   * @default false
   */
  centerAligned?: boolean
  ctaBtnColor?: string
  cta1?: {
    link?: string
    anchor?: string
    buttonText: string
    callback?: (event: React.MouseEvent<HTMLElement>) => void
  }
  cta2?: {
    link?: string
    anchor?: string
    buttonText: string
    callback?: (event: React.MouseEvent<HTMLElement>) => void
  }
  heroImg?:
    | {
        _type: "sanityImg"
        data: SanityImg
      }
    | {
        _type: "cloudinaryImg"
        data: string
      }
  imgSize?: "small" | "large"
  /**
   * denotes whether hero img will be left/right on desktop
   * @default false
   */
  reverseContent?: boolean
  /**
   * denotes whether hero img will be top/bottom on tab/mobile
   * @default false
   */
  reverseContentMobile?: boolean
  shadow?: boolean
  border?: boolean
  background?: "onDark" | "onLight" | string
  className?: string
  /**
   * set a border radius on the hero image. pass a string ex: "10px", "50%"
   */
  imgRadius?: string
}

const SectionHero = ({
  intro,
  introIcon,
  introColor,
  header,
  headerColor,
  subheader,
  subheaderColor,
  body,
  bodyColor,
  bodyPadding = true,
  centerAligned = false,
  cta1,
  cta2,
  heroImg,
  imgSize,
  reverseContent = false,
  reverseContentMobile = true,
  shadow = false,
  border = false,
  background = "onLight",
  className = "hero",
  imgRadius,
  ctaBtnColor
}: SectionHeroProps) => {
  return (
    <SectionHeroStyled
      className={`${className} hero`}
      reverseContent={reverseContent}
      reverseContentMobile={reverseContentMobile}
      shadow={shadow}
      border={border}
      background={background}
      introColor={introColor}
      headerColor={headerColor}
      subheaderColor={subheaderColor}
      bodyColor={bodyColor}
      bodyPadding={bodyPadding}
      imgSize={imgSize}
      centerAligned={centerAligned}
      intro={intro}
      imgRadius={imgRadius}
      ctaBtnColor={ctaBtnColor}
    >
      <AppLayoutWidth constraint="md" className="hero__content-wrapper">
        <div className="hero__content-container">
          <div className="hero__intro-container">
            <div className="hero__intro-icon">
              {introIcon &&
                introIcon?.data && introIcon?._type == "sanityImg" && introIcon?.data?.asset  && (
                  <SanityImage
                    alt={introIcon.data.alt || ""}
                    asset={introIcon.data.asset}
                    crop={introIcon.data.crop}
                    hotspot={introIcon.data.hotspot}
                  />
                )}
              {introIcon &&
                introIcon?.data &&
                introIcon?._type == "cloudinaryImg" &&
                typeof introIcon?.data === "string" && (
                  <img src={introIcon.data} loading="lazy" />
                )}
              {introIcon &&
                introIcon?.data &&
                introIcon?._type == "reactNode" &&
                (introIcon.data as ReactNode)}
            </div>
            {intro && (
              <SemiBdTitle5 className="hero__intro-title">{intro}</SemiBdTitle5>
            )}
          </div>
          {header && <BdTitle2 className="hero__header">{header}</BdTitle2>}
          {subheader && (
            <SemiBdTitle4 className="hero__subheader">{subheader}</SemiBdTitle4>
          )}
          {typeof body === "string" ? (
            <RegBody className="hero__body">{body}</RegBody>
          ) : (
            <div className="hero__body">{body}</div>
          )}
          <div className="hero__btn-container">
            {cta1 && cta1?.link && (
              <Link
                to={cta1.link}
                className="hero__cta primary"
                onClick={cta1.callback}
              >
                <SemiBdTitle5 className="hero__cta-text">
                  {cta1.buttonText}
                </SemiBdTitle5>
              </Link>
            )}
            {cta1 && cta1?.anchor && (
              <a
                href={cta1.anchor}
                className="hero__cta primary"
                onClick={cta1.callback}
              >
                <SemiBdTitle5 className="hero__cta-text">
                  {cta1.buttonText}
                </SemiBdTitle5>
              </a>
            )}
            {cta2 && cta2?.link && (
              <Link
                to={cta2.link}
                className="hero__cta secondary"
                onClick={cta2.callback}
              >
                <SemiBdTitle5 className="hero__cta-text">
                  {cta2.buttonText}
                </SemiBdTitle5>
              </Link>
            )}
            {cta2 && cta2?.anchor && (
              <a
                href={cta2.anchor}
                className="hero__cta secondary"
                onClick={cta2.callback}
              >
                <SemiBdTitle5 className="hero__cta-text">
                  {cta2.buttonText}
                </SemiBdTitle5>
              </a>
            )}
          </div>
        </div>

        <div className="hero__img-container">
          {heroImg && heroImg?._type == "sanityImg" && heroImg?.data && heroImg?.data?.asset && (
            <SanityImage
              alt={heroImg.data.alt || ""}
              asset={heroImg.data.asset}
              className="hero__img"
              crop={heroImg.data.crop}
              hotspot={heroImg.data.hotspot}
            />
          )}
          {heroImg && heroImg?.data && heroImg?._type == "cloudinaryImg" && (
            <img className="hero__img" src={heroImg.data} />
          )}
        </div>
      </AppLayoutWidth>
    </SectionHeroStyled>
  )
}

export default SectionHero

interface SectionHeroSC {
  reverseContent?: boolean
  reverseContentMobile?: boolean
  shadow: boolean
  border: boolean
  background?: "onDark" | "onLight" | string
  introColor?: "onDark" | "onLight" | "primary"
  headerColor?: "onDark" | "onLight" | "primary"
  subheaderColor?: "onDark" | "onLight" | "primary"
  bodyColor?: "onDark" | "onLight" | "primary"
  bodyPadding?: boolean
  imgSize?: "small" | "large"
  centerAligned?: boolean
  intro?: string | ReactNode
  imgRadius?: string
  ctaBtnColor?: string
}

const SectionHeroStyled = styled.section<SectionHeroSC>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .hero__content-wrapper {
    padding: 0;
    display: flex;
    overflow: hidden;
    flex-direction: ${({ reverseContentMobile }) =>
      reverseContentMobile ? "column-reverse" : "column"};
    background: ${({ background }) =>
    {
        switch (background) {
          case "onDark":
            return "var(--color-gray900)"
          case "onLight":
            return "var(--color-white)"
          default:
            return background
        }
      }};
      // background === "onDark" ? "var(--color-gray900)" : "onLight" ? "var(--color-white)" : background};
    text-align: ${({ centerAligned }) => (centerAligned ? "center" : "left")};
    border: ${({ border }) =>
      border ? "1px solid var(--color-gray300)" : "none"};
    box-shadow: ${({ shadow }) =>
      shadow ? "0px 4px 8px 0px rgba(45, 50, 57, 0.20);" : "none"};

    @media ${media.midUp} {
      margin-top: ${rem(24)};
      max-width: ${rem(616)};
      border-radius: ${rem(8)};
    }

    @media ${media.stMidUp} {
      padding: 0;
      margin-top: ${rem(56)};
      flex-direction: ${({ reverseContent }) =>
        reverseContent ? "row-reverse" : "row"};
      align-items: center;
      max-width: ${rem(1312)};
      max-height: ${rem(640)};
    }
  }

  .hero__content-container {
    width: 100%;
    padding: ${rem(56)} ${rem(16)};

    @media ${media.midUp} {
      padding: ${({ bodyPadding }) => (bodyPadding ? "72px 64px" : "72px 0")};
    }

    @media ${media.stMidUp} {
      width: ${({ imgSize }) => {
        switch (imgSize) {
          case "large":
            return "33%"
          case "small":
            return "66%"
          default:
            return "50%"
        }
      }};
    }
  }

  .hero__intro-container {
    display: ${({ intro }) => (intro ? "flex" : "none")};
    align-items: center;
    justify-content: ${({ centerAligned }) =>
      centerAligned ? "center" : "flex-start"};
    gap: ${rem(8)};
    color: ${({ introColor }) => {
      switch (introColor) {
        case "onDark":
          return "var(--color-white)"
        case "onLight":
          return "var(--color-gray800)"
        case "primary":
          return "var(--color-primary)"
        default:
          return "var(--color-primary)"
      }
    }};
    .hero__intro-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${rem(24)};
      height: ${rem(24)};
      border-radius: ${rem(16)};
      border: 1px solid
        ${({ introColor }) => {
          switch (introColor) {
            case "onDark":
              return "var(--color-white)"
            case "onLight":
              return "var(--color-gray800)"
            case "primary":
              return "var(--color-primary)"
            default:
              return "none"
          }
        }};

      @media ${media.midUp} {
        width: ${rem(32)};
        height: ${rem(32)};
      }
    }

    .hero__intro-title {
      @media ${media.midUp} {
        font-size: ${rem(24)};
      }
    }
  }

  .hero__header {
    padding-top: ${rem(8)};
    ${({ centerAligned }) => (centerAligned ? null : `max-width: 400px`)};
    color: ${({ headerColor }) => {
      switch (headerColor) {
        case "onDark":
          return "var(--color-white)"
        case "onLight":
          return "var(--color-gray800)"
        case "primary":
          return "var(--color-primary)"
        default:
          return "var(--color-gray800)"
      }
    }};
    @media ${media.midUp} {
      font-size: ${rem(56)};
      line-height: ${rem(72)};
      ${({ centerAligned }) => (centerAligned ? null : `max-width: 512px`)};
    }
  }

  .hero__subheader {
    padding-top: ${rem(16)};
    ${({ centerAligned }) => (centerAligned ? null : `max-width: 210px`)};
    color: ${({ subheaderColor }) => {
      switch (subheaderColor) {
        case "onDark":
          return "var(--color-white)"
        case "onLight":
          return "var(--color-gray800)"
        case "primary":
          return "var(--color-primary)"
        default:
          return "var(--color-gray800)"
      }
    }};
    @media ${media.midUp} {
      font-size: ${rem(32)};
      line-height: ${rem(40)};
      ${({ centerAligned }) => (centerAligned ? null : `max-width: 544px`)};
    }
  }

  .hero__body {
    padding-top: ${rem(16)};
    ${({ centerAligned }) => (centerAligned ? null : `max-width: 325px`)};
    color: ${({ bodyColor }) => {
      switch (bodyColor) {
        case "onDark":
          return "var(--color-white)"
        case "onLight":
          return "var(--color-gray800)"
        case "primary":
          return "var(--color-primary)"
        default:
          return "var(--color-gray800)"
      }
    }};
    @media ${media.midUp} {
      ${({ centerAligned }) => (centerAligned ? null : `max-width: 616px`)};
    }

    @media ${media.stMidUp} {
      ${({ centerAligned }) => (centerAligned ? null : `max-width: 491px`)};
    }
  }

  .hero__btn-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: ${({ centerAligned }) =>
      centerAligned ? "center" : "flex-start"};
    justify-content: ${({ centerAligned }) =>
      centerAligned ? "center" : "flex-start"};
    gap: ${rem(16)};
    padding-top: ${rem(32)};

    @media ${media.midUp} {
      flex-direction: row;
    }

    .hero__cta {
      width: fit-content;
      height: ${rem(40)};
      color: var(--color-white);
      background: ${({ ctaBtnColor }) =>
      ctaBtnColor ? ctaBtnColor : "var(--color-primary)"};
      border-radius: ${rem(4)};
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      padding: 0 ${rem(32)};

      @media ${media.midUp} {
        .hero__cta-text {
          font-size: ${rem(16)};
        }
      }
    }

    .hero__cta.secondary {
      background: var(--color-gray300);
    }
  }

  .hero__img-container {
    display: flex;
    overflow: hidden;
    ${({ imgRadius }) => (imgRadius ? `border-radius: ${imgRadius}` : null)};
    margin: 0 ${rem(16)};
    @media ${media.midUp} {
      margin: 0;
    }

    @media ${media.stMidUp} {
      width: ${({ imgSize }) => {
        switch (imgSize) {
          case "large":
            return "66%"
          case "small":
            return "33%"
          default:
            return "50%"
        }
      }};

      .hero__img {
        object-fit: cover;
        width: 100%;
      }
    }
  }
`
