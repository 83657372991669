import React from "react"
import { IconProps } from "./iconTypes"

const IconHandshake = ({
  size = 24,
  width,
  height,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 108 72"
    width={flexible ? undefined : width ? width : size}
    height={flexible ? undefined : height ? height : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <g id="Layer_2" clipPath="url(#clip0_1836_1332)">
      <g id="Group">
        <path
          id="Vector"
          d="M22.6696 54.3636C19.7935 51.9964 21.6517 49.2738 23.0251 46.8016C24.1885 44.7253 25.5619 40.7666 28.5269 42.0027C32.1705 43.5215 29.7953 47.2379 28.5269 49.5162C27.2585 51.7944 25.7558 56.0359 22.4434 54.1777C21.1589 53.4506 20.0036 55.43 21.2881 56.1571C25.9093 58.7504 28.3007 54.6221 30.2477 51.14C32.1948 47.658 34.6023 43.2145 30.3932 40.2415C26.184 37.2684 23.2675 41.6795 21.4497 44.9273C19.4542 48.5063 17.0386 52.6589 21.0538 55.9713C22.1849 56.9004 23.8169 55.2926 22.6777 54.3474L22.6696 54.3636Z"
          fill="#127D89"
        />
        <path
          id="Vector_2"
          d="M28.8097 58.8475C27.4524 58.8475 28.4623 54.5171 29.1005 53.1922C29.7387 51.8672 30.5709 50.5584 31.298 49.2577L33.7136 44.9354C34.9255 42.7703 34.3519 42.9076 37.333 42.8995C40.1445 42.8995 41.3563 42.5521 39.7163 46.7532C39.5628 47.1491 39.2639 47.4965 39.0861 47.8843C38.4398 49.2738 37.5754 50.5907 36.824 51.9318C35.7253 53.895 33.1723 61.0045 29.3914 59.009C28.0906 58.3223 26.9273 60.3017 28.2361 60.9884C34.1741 64.123 36.7271 56.8115 38.9892 52.764C41.114 48.9588 45.6867 41.8654 39.0861 39.369C33.5924 37.2927 30.9425 45.2101 28.9632 48.7487C27.9775 50.518 26.5718 52.3923 25.9901 54.3636C25.4084 56.3349 26.079 61.1419 28.8097 61.1419C30.2881 61.1419 30.2881 58.8475 28.8097 58.8475Z"
          fill="#127D89"
        />
        <path
          id="Vector_3"
          d="M36.5736 63.9938C33.3824 61.3197 36.0646 57.983 37.5835 55.2685C38.7872 53.1033 40.9039 47.4319 44.3698 49.9687C46.947 51.8511 44.4344 55.5836 43.158 57.8699C41.8815 60.1563 39.9183 65.7712 36.3555 63.8161C35.0628 63.1051 33.8994 65.0845 35.2002 65.7954C40.4192 68.6473 42.7621 63.2829 44.8465 59.5584C46.9309 55.834 49.9443 50.8654 45.1292 47.8197C40.5888 44.9516 37.9228 49.9929 36.0646 53.3214C33.9075 57.1751 30.5305 61.8933 34.9739 65.6177C36.0969 66.5629 37.7289 64.9471 36.5978 63.9938H36.5736Z"
          fill="#127D89"
        />
        <path
          id="Vector_4"
          d="M43.5296 68.8169C41.0089 66.7649 42.1481 64.3654 43.4246 62.0791C44.5395 60.0835 45.9694 56.1895 48.9183 57.7568C51.8671 59.3241 50.1948 62.5153 49.0071 64.6482C47.8195 66.781 46.438 70.4408 43.2953 68.6392C42.0107 67.904 40.8554 69.8834 42.14 70.6186C46.5188 73.1311 48.9748 69.4067 50.8653 66.0216C52.7558 62.6365 54.3231 58.6455 50.5341 55.9794C48.7809 54.7514 46.5027 54.7756 44.7738 56.0198C43.4165 56.9974 42.7298 58.6212 41.938 60.0431C40.0314 63.4525 38.149 67.3789 41.9138 70.4327C43.0529 71.3537 44.6768 69.746 43.5377 68.8089L43.5296 68.8169Z"
          fill="#127D89"
        />
      </g>
      <g id="Group_2">
        <path
          id="Vector_5"
          d="M78.2047 13.6939C74.2783 15.447 70.8851 14.6068 67.5727 11.9569C66.4174 11.0359 65.5045 10.0664 64.0906 9.54125C61.9336 8.73335 59.2594 9.07267 57.0054 9.07267C54.9937 9.07267 52.7558 8.79799 50.8734 9.64628C47.1086 11.3348 43.7235 15.6086 40.7666 18.3635C37.4704 21.4255 31.2899 27.8886 39.3124 29.5125C43.8285 30.4255 48.6921 27.5413 51.9964 24.6974L50.6068 24.8752C58.7747 30.5386 66.9506 36.21 75.1185 41.8734C77.938 43.8285 81.8402 45.6786 84.2154 48.1831C87.0511 51.1723 84.6921 55.333 80.588 53.6283C79.5538 53.2001 78.5682 52.2307 77.6472 51.5924C75.6678 50.219 73.6885 48.8456 71.7091 47.4802C70.4973 46.64 69.3501 48.6274 70.5538 49.4596C73.0987 51.2289 75.6517 52.9901 78.1966 54.7594C80.4345 56.3105 82.745 57.684 85.395 55.9389C88.8124 53.6849 88.4327 48.8698 85.6212 46.3976C84.6436 45.5413 83.4479 44.8707 82.3896 44.1355C72.1777 37.0583 61.974 29.973 51.7621 22.8958C51.3258 22.5888 50.7441 22.7585 50.3725 23.0736C49.3384 23.9703 48.175 24.7378 46.9874 25.4165C45.3474 26.3536 39.6598 29.0439 38.1086 26.6526C36.3959 24.0107 43.5054 18.9775 45.0969 17.5233C47.5045 15.3258 50.1382 11.8276 53.5395 11.3671C57.8294 10.7854 62.386 11.2055 65.9488 13.5888C70.5781 16.6831 73.947 18.105 79.3599 15.6894C80.7011 15.0915 79.5377 13.1122 78.2047 13.71V13.6939Z"
          fill="#127D89"
        />
        <path
          id="Vector_6"
          d="M69.6167 48.8052C70.8447 49.6535 72.0727 50.5099 73.3007 51.3582C74.1086 51.9156 74.7791 52.3923 75.1993 53.3537C76.0233 55.2603 75.0215 58.2657 72.5736 58.2496C71.0386 58.2334 69.3097 56.553 68.1301 55.7289C67.0395 54.9776 65.9488 54.2181 64.8662 53.4668C63.6544 52.6266 62.5071 54.6059 63.7109 55.4461C67.0637 57.7648 72.1131 62.9111 76.0879 59.0898C78.5359 56.7307 78.3177 52.5539 75.8133 50.3564C74.2944 49.0233 72.4524 47.9812 70.7881 46.8259C69.5763 45.9856 68.4291 47.9731 69.6328 48.8052H69.6167Z"
          fill="#127D89"
        />
        <path
          id="Vector_7"
          d="M63.7028 55.4461C64.7208 56.1571 65.8195 56.8034 66.789 57.5871C69.0269 59.3887 68.7441 64.535 64.9793 63.9452C62.9515 63.6302 60.6328 61.2873 58.9443 60.1158C57.7325 59.2756 56.5852 61.263 57.789 62.0952C60.8833 64.2361 64.9228 68.2675 68.6472 65.0521C70.9739 63.0404 71.2486 59.3402 69.3905 56.9327C68.2675 55.4785 66.3528 54.509 64.8662 53.4749C63.6544 52.6347 62.5071 54.614 63.7109 55.4542L63.7028 55.4461Z"
          fill="#127D89"
        />
        <path
          id="Vector_8"
          d="M57.789 62.087C58.4919 62.5718 59.3079 63.0161 59.938 63.5897C61.6669 65.1813 61.57 68.4937 59.2352 69.5116C57.1669 70.4084 54.6301 67.8554 53.0305 66.7486C51.8186 65.9084 50.6714 67.8877 51.8752 68.728C54.8887 70.8204 58.7262 74.0278 61.982 70.2791C63.5736 68.4533 63.8644 65.7307 62.7657 63.5816C61.9497 61.982 60.3743 61.1095 58.9443 60.1238C57.7325 59.2836 56.5852 61.2711 57.789 62.1032V62.087Z"
          fill="#127D89"
        />
      </g>
      <path
        id="Vector_9"
        d="M27.7271 14.9543C30.3609 15.956 32.9865 16.3438 35.7011 15.3744C37.7612 14.6392 42.4309 9.61404 44.4345 10.7451C45.719 11.4722 46.8743 9.49285 45.5898 8.76574C43.4488 7.55389 41.7522 7.99016 39.9345 9.58172C36.2908 12.781 33.2208 14.6069 28.333 12.7487C26.9515 12.2236 26.3537 14.4372 27.7271 14.9623V14.9543Z"
        fill="#127D89"
      />
      <path
        id="Vector_10"
        d="M89.588 36.315C86.5664 37.4461 84.4821 40.1525 83.9731 43.3195C83.7388 44.7657 85.9444 45.3797 86.1867 43.9254C86.5584 41.5987 87.9076 39.377 90.202 38.5206C91.5673 38.0035 90.9776 35.7899 89.5961 36.3069L89.588 36.315Z"
        fill="#127D89"
      />
      <path
        id="Vector_11"
        d="M21.6759 39.5628C21.0296 37.4057 19.3411 35.5637 17.079 35.1113C15.6409 34.8285 15.0188 37.0341 16.4731 37.3249C17.9273 37.6158 19.0341 38.7387 19.4704 40.1768C19.9066 41.6149 22.1041 40.9847 21.684 39.5709L21.6759 39.5628Z"
        fill="#127D89"
      />
      <path
        id="Vector_12"
        d="M82.0907 10.4946C85.4596 7.82042 88.8285 5.14628 92.1975 2.47213L90.3959 2.23784C95.2433 12.8375 100.091 23.4452 104.938 34.0448L105.35 32.4775L94.4273 38.0682L95.9946 38.4802C91.4138 28.6885 86.8411 18.8886 82.2603 9.09691C81.6382 7.76387 79.6589 8.92725 80.281 10.2522C84.8617 20.0439 89.4345 29.8438 94.0152 39.6355C94.2899 40.2172 95.0736 40.3061 95.5826 40.0475L106.505 34.4569C107.095 34.1579 107.16 33.4228 106.917 32.8895C102.07 22.2899 97.2226 11.6822 92.3752 1.08254C92.0359 0.339276 91.0906 0.444303 90.5736 0.848252C87.2047 3.5224 83.8357 6.19655 80.4668 8.87069C79.3115 9.78362 80.9434 11.3994 82.0907 10.4946Z"
        fill="#127D89"
      />
      <path
        id="Vector_13"
        d="M26.588 8.33751C23.219 5.66337 19.8501 2.98922 16.4812 0.315072C15.9641 -0.0969568 15.0189 -0.193905 14.6795 0.549363C9.83214 11.149 4.98474 21.7486 0.137348 32.3564C-0.105022 32.8977 -0.048469 33.6167 0.549377 33.9237C4.193 35.7899 7.82855 37.6562 11.4722 39.5144C11.9812 39.7729 12.7648 39.6921 13.0395 39.1023C17.6203 29.3106 22.193 19.5108 26.7738 9.71902C27.3959 8.39407 25.4165 7.22261 24.7944 8.56373C20.2136 18.3555 15.6409 28.1553 11.0601 37.947L12.6275 37.535C8.98385 35.6688 5.3483 33.8025 1.70467 31.9443L2.1167 33.5117C6.9641 22.912 11.8115 12.3043 16.6589 1.70466L14.8573 1.93895C18.2262 4.6131 21.5952 7.28724 24.9641 9.96139C26.1113 10.8662 27.7433 9.25852 26.588 8.33751Z"
        fill="#127D89"
      />
    </g>
    <defs>
      <clipPath id="clip0_1836_1332">
        <rect width="107.055" height="72" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IconHandshake
