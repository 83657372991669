import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { rem } from "polished"
import { media, useMedia } from "@sr/common"
import { AnimatePresence, motion } from "framer-motion"
import { Link } from "gatsby"
import { globalHistory } from "@reach/router"

import {
  BdTitle5,
  BrandingMark,
  BrandingSecondary,
  RegBody,
} from "@sr/gatsby-theme-ui"
import { OtherNavInfo } from "../navData"

import AppHeaderNavicon from "./NavMobileIcon"
import AppHeaderMobileAccordionContainer from "./NavMobileAccordion"
import AppHeaderNavMobileLoginAccordion from "./NavMobileLoginAccordion"

export interface NavigationNavData {
  content: string
  featured: string | boolean
}

const AppHeaderMobileContainer = ({ navigation, className }: any) => {
  const [mobileNavExpanded, setMobileNavExpanded] = useState(false)

  const navRef = useRef<null | HTMLDivElement>(null)
  const isMobile: boolean = !useMedia(media.midUp)

  //close flyout when navigating to another page
  useEffect(() => {
    const historyUnsubscribe = globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setMobileNavExpanded(false)
      }
    })

    return () => historyUnsubscribe()
  }, [])

  //close flyout if user clicks outside of element
  const handleClickOutside = (event: any) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setMobileNavExpanded(false)
    }
  }
  //add event listener to allow click outside flyout
  useEffect(() => {
    if (mobileNavExpanded) {
      document.addEventListener("click", handleClickOutside, true)
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [mobileNavExpanded])

  //enable scroll lock only on mobile
  useEffect(() => {
    if (isMobile && mobileNavExpanded) {
      document.body.style.overflow = "hidden"
    }
    return () => {
      document.body.style.overflow = "scroll"
    }
  }, [isMobile, mobileNavExpanded])

  return (
    <AppHeaderMobileContainerStyled
      role="banner"
      className={className}
      mobileNavExpanded={mobileNavExpanded}
    >
      <div className="header__mobile-container">
        <div className="header__mobile-navigation-container">
          <div className="header__mobile-brand">
            <Link to="/">
              <BrandingMark
                width={64 * 0.7}
                height={54 * 0.7}
                className="header__brand__mark"
              />
            </Link>
          </div>
          <AppHeaderNavicon
            className="header__mobile-navicon"
            onTap={() => setMobileNavExpanded(!mobileNavExpanded)}
            isOpen={mobileNavExpanded}
          />
        </div>
        <AnimatePresence>
          {mobileNavExpanded && (
            <motion.aside
              ref={navRef}
              className="header__mobile-flyout"
              initial={{ x: "100vw" }}
              animate={{ x: 0, transition: { duration: 0.4 } }}
              exit={{ x: "100vw", transition: { duration: 0.4 } }}
            >
              <div className="header__mobile-flyout-navigation-container">
                <div className="header__mobile-brand">
                  <Link to="/">
                    <BrandingSecondary
                      width={152}
                      height={20}
                      className="header__brand__mark"
                    />
                  </Link>
                </div>
                <AppHeaderNavicon
                  className="header__mobile-navicon"
                  onTap={() => setMobileNavExpanded(!mobileNavExpanded)}
                  isOpen={mobileNavExpanded}
                />
              </div>
              <AppHeaderMobileAccordionContainer
                accordionItems={navigation}
                defaultActiveItem="Solutions"
              />
              <div className="header__mobile-navigation-login-container">
                <div className="header__mobile-navigation-login-link-container">
                  {OtherNavInfo?.navInfo.map((item, index) => {
                    const { nav, link, internal } = item

                    if (nav === "Login" && item.subNavItems) {
                      return (
                        <AppHeaderNavMobileLoginAccordion
                          nav={nav}
                          subNavItems={item.subNavItems}
                        />
                      )
                    } else {
                      return internal ? (
                        <Link to={link} key={index}>
                          <RegBody>{nav}</RegBody>
                        </Link>
                      ) : (
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={index}
                        >
                          <RegBody>{nav}</RegBody>
                        </a>
                      )
                    }
                  })}
                </div>
                <Link
                  to={OtherNavInfo?.demo?.link}
                  className="header__mobile-navigation-demo"
                >
                  <BdTitle5>{OtherNavInfo?.demo?.nav}</BdTitle5>
                </Link>
              </div>
            </motion.aside>
          )}
        </AnimatePresence>
      </div>
    </AppHeaderMobileContainerStyled>
  )
}

export default AppHeaderMobileContainer

type AppHeaderMobileContainerStyledSC = {
  mobileNavExpanded: boolean
}

const AppHeaderMobileContainerStyled = styled.div<AppHeaderMobileContainerStyledSC>`
  height: 100%;
  width: 100%;
  background: var(--color-content-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .header__mobile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: var(--color-content-bg);
    height: 100%;
  }

  .header__mobile-navigation-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${rem(24)} 0 ${rem(20)};
  }

  .header__mobile-flyout {
    position: fixed;
    height: 100svh;
    height: -webkit-fill-available;
    width: 100vw;
    top: 0;
    right: 0;
    overflow: auto;
    background: var(--color-content-bg);

    @media ${media.midUp} {
      width: 376px;
      box-shadow: 0 ${rem(4)} ${rem(8)} 0 rgba(45, 50, 57, 0.2);
    }
  }

  .header__mobile-flyout-navigation-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${rem(24)} ${rem(24)} ${rem(12)} ${rem(40)};
  }

  .header__mobile-accordion {
    width: 100%;
  }

  .header__mobile-navigation-login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    .header__mobile-navigation-login-link-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: ${rem(16)};
      padding: ${rem(16)} ${rem(48)} ${rem(40)};
      p {
        color: var(--color-gray800);
      }
    }
    .header__mobile-navigation-demo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: ${rem(56)};
      width: 100%;
      background-color: var(--color-primary);
      color: var(--color-white);
      text-transform: uppercase;
    }
  }
`
