import React from "react"
import { IconProps } from "./iconTypes"

const IconSupportPerson = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 67 72"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <svg
      width="67"
      height="72"
      viewBox="0 0 67 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_2" clipPath="url(#clip0_1836_1349)">
        <path
          id="Vector"
          d="M48.5682 34.6028C48.5682 42.9932 40.1778 51.3837 33.1916 51.3837C26.2053 51.3837 17.8149 42.9932 17.8149 34.6028V23.4179C29.4958 23.4179 39.4931 18.4157 39.4931 12.2329C39.4931 18.4157 42.7208 23.4179 48.5682 23.4179V34.6028Z"
          stroke="#127D89"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M49.9655 34.6028H48.5682V23.4178H49.9655C53.0534 23.4178 55.5545 25.9189 55.5545 29.0138C55.5545 32.1087 53.0534 34.6028 49.9655 34.6028Z"
          stroke="#127D89"
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M16.4246 34.6028H17.8219V23.4178H16.4246C13.3367 23.4178 10.8356 25.9189 10.8356 29.0138C10.8356 32.1087 13.3367 34.6028 16.4246 34.6028Z"
          stroke="#127D89"
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M35.9861 44.3904C35.2176 44.3904 34.5888 43.7617 34.5888 42.9932C34.5888 42.2247 35.2176 41.5959 35.9861 41.5959C36.7545 41.5959 37.3833 42.2247 37.3833 42.9932C37.3833 43.7617 36.7545 44.3904 35.9861 44.3904Z"
          stroke="#127D89"
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M48.5682 34.6028C48.5682 38.4592 44.1949 42.9932 38.7805 42.9932H37.3833"
          stroke="#127D89"
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          id="Vector_6"
          d="M62.5476 70.9521C62.5476 66.4809 61.4159 62.2822 59.4737 58.5935"
          stroke="#127D89"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_7"
          d="M6.91634 58.5935C4.9602 62.2822 3.84241 66.4809 3.84241 70.9521"
          stroke="#127D89"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_8"
          d="M42.9792 68.1576H51.3627"
          stroke="#127D89"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_9"
          d="M40.1847 48.9455V49.9795C40.1847 55.5754 47.171 59.7672 53.8638 59.7672C60.9618 59.7672 65.3421 54.9676 65.3421 50.1751C62.7712 50.1751 58.9078 48.9735 58.9078 42.9932C58.9078 15.4326 53.7659 1.04797 33.1915 1.04797C12.6171 1.04797 7.47524 15.4326 7.47524 42.9932C7.47524 48.9944 3.61884 50.1751 1.04791 50.1751C1.04791 54.9746 5.42129 59.7672 12.5193 59.7672C19.2121 59.7672 26.2053 55.5754 26.2053 49.9795V48.9455"
          stroke="#127D89"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_10"
          d="M23.3969 55.7571L33.1915 65.3631L42.9862 55.7571"
          stroke="#127D89"
          strokeWidth="3"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1836_1349">
          <rect width="66.3901" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </svg>
)

export default IconSupportPerson
