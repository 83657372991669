import React from "react"
import { IconProps } from "./iconTypes"

const IconCogRotate = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 72 72"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_2" clipPath="url(#clip0_1896_2750)">
        <path
          id="Vector"
          d="M45.6253 36.0272C45.6253 41.3482 41.3075 45.666 35.9865 45.666C30.6654 45.666 26.3476 41.3482 26.3476 36.0272C26.3476 30.7061 30.6654 26.3883 35.9865 26.3883C41.3075 26.3883 45.6253 30.7061 45.6253 36.0272Z"
          stroke="#127D89"
          strokeWidth="3.22"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M59.887 61.4664C49.184 71.5052 32.8413 74.0471 19.2642 66.6315C2.34532 57.3858 -3.87046 36.1762 5.37526 19.2642"
          stroke="#127D89"
          strokeWidth="3.22"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M52.4918 61.1613L60.3819 61.1274L58.3416 68.8209"
          stroke="#127D89"
          strokeWidth="3.22"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M12.1198 10.5336C22.816 0.494859 39.1587 -2.04704 52.7358 5.3753C69.6547 14.621 75.8705 35.8238 66.6247 52.7426"
          stroke="#127D89"
          strokeWidth="3.22"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M19.5963 10.8183L11.7131 10.7166L13.8822 3.06384"
          stroke="#127D89"
          strokeWidth="3.22"
          strokeLinejoin="round"
        />
        <path
          id="Vector_6"
          d="M22.4907 47.4826L16.4783 48.4383L13.428 41.0973L18.3491 37.5116C18.2677 36.5422 18.2542 35.5662 18.3355 34.5833L13.4212 31.0111L16.4579 23.6633L22.4636 24.6055C23.1007 23.8531 23.7989 23.1685 24.5378 22.5381L23.582 16.5257L30.923 13.4754L34.5088 18.3965C35.4781 18.3152 36.4542 18.3016 37.437 18.383L41.0092 13.4686L48.357 16.5053L47.4148 22.511C48.1672 23.1482 48.8518 23.8463 49.4822 24.5852L55.4946 23.6294L58.5449 30.9704L53.6238 34.5562C53.7051 35.5255 53.7187 36.5016 53.6374 37.4844L58.5517 41.0567L55.515 48.4044L49.5093 47.4622C48.8722 48.2146 48.174 48.8992 47.4351 49.5296L48.3909 55.5421L41.0499 58.5923L37.4641 53.6712C36.4948 53.7526 35.5187 53.7661 34.5359 53.6848L30.9637 58.5991L23.6159 55.5624L24.5581 49.5567C23.8057 48.9196 23.1211 48.2214 22.4907 47.4826Z"
          stroke="#127D89"
          strokeWidth="3.22"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1896_2750">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </svg>
)

export default IconCogRotate
