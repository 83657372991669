import { SVGProps } from "react"

export const iconNames = [
  "IconArrowLeft",
  "IconArrowRight",
  "IconAuto",
  "IconBulbOn",
  "IconCalendar",
  "IconCamera",
  "IconCar",
  "IconCheck",
  "IconCheckCircleSolid",
  "IconCheckSolid",
  "IconChevronCircleRight",
  "IconChevronDown",
  "IconChevronLeft",
  "IconChevronRight",
  "IconChevronUp",
  "IconCircleCheck",
  "IconClipboardFilled",
  "IconClipboardTask",
  "IconClock",
  "IconClose",
  "IconCloseCircleSolid",
  "IconCloudSync",
  "IconCog",
  "IconCogRotate",
  "IconCommunityParking",
  "IconDollarSign",
  "IconDoorOpen",
  "IconEmail",
  "IconExclamationCircleOutline",
  "IconExclamationCircleSolid",
  "IconFacebook",
  "IconGarageDoorOpen",
  "IconGlobeSimple",
  "IconGraph",
  "IconGrid",
  "IconHelp",
  "IconHighRiseBuilding",
  "IconHeadset",
  "IconHome",
  "IconHub",
  "IconInstagram",
  "IconIntercom",
  "IconKey",
  "IconKeyFob",
  "IconLinkedIn",
  "IconLock",
  "IconMap",
  "IconMission",
  "IconMobilePhone",
  "IconNewFeature",
  "IconOnline",
  "IconOptions",
  "IconOutlet",
  "IconPaperPlane",
  "IconParking",
  "IconParkingManagement",
  "IconPerson",
  "IconPhone",
  "IconPlayCircle",
  "IconPower",
  "IconQuestionCircleBorder",
  "IconRocket",
  "IconScreen",
  "IconSearch",
  "IconSecurityCamera",
  "IconShield",
  "IconShieldPerson",
  "IconSmartDoorbell",
  "IconSmartHome",
  "IconSnowflakeFlame",
  "IconSpeaker",
  "IconStar",
  "IconThermostat",
  "IconTrendUp",
  "IconTwitter",
  "IconUnlock",
  "IconUsers",
  "IconVisible",
  "IconWaterDrop",
  "IconWifi",
  "IconWindowShadeClosed",
  "IconWireConnected",
] as const

export type IconName = (typeof iconNames)[number]

export interface IconProps extends SVGProps<SVGSVGElement> {
  size?: number
  flexible?: boolean
}

export type IconType = IconProps
