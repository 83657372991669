import React from "react"
import { IconProps } from "./iconTypes"

const IconMission = ({
  size = 24,
  flexible = false,
  className,
  color,
  ...props
}: IconProps) => (
  <svg
    {...props}
    viewBox="0 0 51 40"
    width={flexible ? undefined : size}
    height={flexible ? undefined : size}
    fill={color}
    className={`icon ${className ? className : ""}`}
  >
<circle cx="33" cy="18" r="18" fill="#209DAB"/>
<path fillRule="evenodd" clipRule="evenodd" d="M12.6638 11.1744C12.7427 11.4827 12.5568 11.7966 12.2484 11.8755C9.26561 12.639 5.62483 15.1594 1.85463 18.8234L6.96836 21.1417C7.25821 21.2731 7.38666 21.6145 7.25526 21.9044C7.12386 22.1942 6.78237 22.3227 6.49252 22.1913L0.614909 19.5268C0.442683 19.4487 0.319725 19.2911 0.285892 19.1051C0.252059 18.919 0.311658 18.7282 0.445369 18.5945C4.45817 14.5817 8.50987 11.6428 11.9627 10.759C12.271 10.6801 12.5849 10.8661 12.6638 11.1744Z" fill="#F5F7FA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M28.7478 27.3606C29.0553 27.4423 29.2384 27.758 29.1566 28.0655C28.2447 31.4961 25.3267 35.509 21.3456 39.4901C21.2119 39.6238 21.0211 39.6834 20.8351 39.6496C20.6491 39.6158 20.4915 39.4929 20.4134 39.3207L17.7673 33.4892C17.6358 33.1994 17.7641 32.8579 18.0539 32.7264C18.3437 32.5949 18.6853 32.7232 18.8168 33.013L21.1164 38.0811C24.7484 34.3439 27.254 30.7371 28.0429 27.7694C28.1246 27.4619 28.4402 27.2788 28.7478 27.3606Z" fill="#F5F7FA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M39.9808 0.605003L39.4053 0.576237L39.3767 0.000710257C39.5393 -0.00736481 39.6978 0.053711 39.8129 0.168841C39.9279 0.283972 39.989 0.442413 39.9808 0.605003ZM30.2287 23.6184C30.2287 23.6184 30.2288 23.6183 30.2287 23.6184C34.7734 19.076 37.2061 13.3133 38.5043 8.71541C39.1545 6.41248 39.5232 4.39105 39.7295 2.94368C39.8326 2.21973 39.8953 1.63864 39.9322 1.23686C39.9507 1.03595 39.9628 0.879818 39.9703 0.773006C39.974 0.719599 39.9766 0.678516 39.9783 0.650328L39.9802 0.617784L39.9806 0.608897L39.9808 0.605003C39.9809 0.604726 39.9808 0.605003 39.4053 0.576237C39.3767 0.000710249 39.377 0.000696354 39.3767 0.000710257L39.3728 0.000909356L39.364 0.001384L39.3314 0.00324656C39.3032 0.00492299 39.2622 0.0075077 39.2088 0.0112317C39.102 0.0186794 38.9459 0.0306858 38.7451 0.0491008C38.3434 0.0859268 37.7625 0.148406 37.0387 0.251365C35.5917 0.457205 33.5706 0.825272 31.268 1.47492C26.6706 2.77199 20.908 5.20348 16.3633 9.74812C12.4534 13.658 10.1151 18.4584 8.71029 22.6496C8.64082 22.8569 8.69462 23.0857 8.8492 23.2402L16.7367 31.1278C16.8913 31.2823 17.12 31.3361 17.3273 31.2667C21.5182 29.8619 26.3233 27.5237 30.2287 23.6184ZM38.7779 1.2035C38.3968 1.23952 37.8612 1.29844 37.201 1.39235C35.7942 1.59247 33.8248 1.95104 31.5809 2.58409C27.0854 3.85242 21.5307 6.21061 17.1782 10.563C13.5382 14.203 11.2984 18.6752 9.91763 22.6788L17.2981 30.0593C21.3022 28.6785 25.7785 26.4387 29.4139 22.8033C33.7663 18.4532 36.1257 12.8984 37.3952 8.40226C38.0288 6.15811 38.388 4.18824 38.5885 2.78108C38.6826 2.12059 38.7417 1.58474 38.7779 1.2035Z" fill="#F5F7FA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.69638 24.8225C6.92141 24.5974 7.28626 24.5974 7.5113 24.8225L15.1775 32.4887C15.4026 32.7137 15.4026 33.0786 15.1775 33.3036C14.9525 33.5287 14.5877 33.5287 14.3626 33.3036L6.69638 25.6374C6.47134 25.4123 6.47134 25.0475 6.69638 24.8225Z" fill="#F5F7FA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M25.1193 12.1793C23.6351 12.1793 22.4317 13.3827 22.4317 14.8669C22.4317 16.3511 23.6351 17.5545 25.1193 17.5545C26.6035 17.5545 27.8068 16.3511 27.8068 14.8669C27.8068 14.5486 28.0648 14.2907 28.3831 14.2907C28.7013 14.2907 28.9593 14.5486 28.9593 14.8669C28.9593 16.9876 27.24 18.7069 25.1193 18.7069C22.9986 18.7069 21.2792 16.9876 21.2792 14.8669C21.2792 12.7462 22.9986 11.0269 25.1193 11.0269C25.4375 11.0269 25.6955 11.2848 25.6955 11.6031C25.6955 11.9213 25.4375 12.1793 25.1193 12.1793Z" fill="#F5F7FA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.37248 28.229C6.59761 28.4539 6.59777 28.8188 6.37283 29.0439L0.983871 34.4375C0.758933 34.6626 0.39408 34.6628 0.16895 34.4378C-0.0561805 34.2129 -0.0563365 33.848 0.168601 33.6229L5.55756 28.2293C5.7825 28.0042 6.14735 28.0041 6.37248 28.229Z" fill="#F5F7FA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.7662 33.6273C11.9913 33.8524 11.9913 34.2172 11.7662 34.4423L6.37727 39.8312C6.15223 40.0563 5.78738 40.0563 5.56235 39.8312C5.33731 39.6062 5.33731 39.2413 5.56235 39.0163L10.9513 33.6273C11.1763 33.4023 11.5412 33.4023 11.7662 33.6273Z" fill="#F5F7FA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.06022 30.9167C9.28525 31.1418 9.28525 31.5066 9.06022 31.7317L5.79181 35.0001C5.56678 35.2251 5.20192 35.2251 4.97689 35.0001C4.75185 34.775 4.75186 34.4102 4.97689 34.1851L8.2453 30.9167C8.47033 30.6917 8.83519 30.6917 9.06022 30.9167Z" fill="#F5F7FA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M30.8892 4.31752C31.1144 4.09257 31.4792 4.09271 31.7042 4.31783L37.6279 10.2462C37.8528 10.4713 37.8527 10.8361 37.6275 11.0611C37.4024 11.286 37.0376 11.2859 36.8126 11.0608L30.8889 5.13244C30.664 4.90732 30.6641 4.54246 30.8892 4.31752Z" fill="#F5F7FA"/>

  </svg>
)

export default IconMission
